@import url("https://fonts.googleapis.com/css?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");


$primary-color: #ED1C24;
$secondary-color: #000;
$tertiary-color: #272839;


@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"),
    url("./assets/Fonts/static/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Bold";
  src: local("Rubik-Bold"),
    url("./assets/Fonts/static/Rubik-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Black";
  src: local("Rubik-Black"),
    url("./assets/Fonts/static/Rubik-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Medium";
  src: local("Rubik-Medium"),
    url("./assets/Fonts/static/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-ExtraBold";
  src: local("Rubik-ExtraBold"),
    url("./assets/Fonts/static/Rubik-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Light";
  src: local("Rubik-Light"),
    url("./assets/Fonts/static/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "NotoKufiArabic-Regular";
  src: local("NotoKufiArabic-Regular"),
    url("./assets/Fonts/static/NotoKufiArabic-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NotoKufiArabic-Bold";
  src: local("NotoKufiArabic-Bold"),
    url("./assets/Fonts/static/NotoKufiArabic-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NotoKufiArabic-Black";
  src: local("NotoKufiArabic-Black"),
    url("./assets/Fonts/static/NotoKufiArabic-Black.ttf") format("truetype");
}

@font-face {
  font-family: "NotoKufiArabic-Medium";
  src: local("NotoKufiArabic-Medium"),
    url("./assets/Fonts/static/NotoKufiArabic-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./assets/Fonts/static/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("./assets/Fonts/static/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./assets/Fonts/static/Montserrat-Bold.ttf") format("truetype");
}

.Montserrat-Medium {
  font-family: "Montserrat-Medium", sans-serif;
}


.App {
  font-family: "Rubik-Regular", sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.brown-regular-header-text {
  color: $primary-color !important;
  font-size: 17px !important;
  font-family: "Rubik-Bold", sans-serif;

}

.text-area-max-height {
  min-height: 200px !important;
  max-height: 300px !important;
}

.language-change-container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  background-color: transparent;
  color: $primary-color !important;
  border-radius: 50%;
  border: 2px solid $primary-color;
  font-size: 13px;
  cursor: pointer;
  font-family: "NotoKufiArabic-Regular", sans-serif !important;
  font-weight: 900;
  padding: 2px 7px;

  @media screen and (max-width:767px) {
    margin: 10px auto !important;
  }

  span {
    margin-bottom: 2px;
  }


}

.white-back-style {
  background-color: white !important;
}

.black-light-body-text {
  color: $secondary-color !important;
  font-size: 14px !important;
  font-family: "Rubik-Bold", sans-serif;
}

.extra-btn-style {
  text-align: center;
  width: 300px !important;
}

.grey-regular-text {
  color: #979598 !important;

}

.bottom-copy {
  background-color: #dedfdf;
  font-family: "Rubik-Bold", sans-serif !important;
  font-size: 10px;
}

.remove-margin-imp {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  // font-size: calc(10px + 2vmin);
  position: relative;
  background-color: rgba(98, 98, 98, 0.1) !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.section {
  /* margin: 0px auto; */
  padding: 00px 30px 0px 30px;
}

.white-bg {
  background-color: white;
}

.grey-bg {
  background-color: #fbfbfb;
}

.blue-bg {
  background-color: #2b87b4;
}

.light-green-bg {
  background-color: $primary-color;
}

.bg-primary {
  background-color: #e9eaea !important; // #2484c6 !important;
}

.bg-grey {
  background-color: #fff !important;
}

.navbar {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  color: #764200;
  margin-bottom: 50px;
  border-bottom: 2px solid $primary-color;
}

// .navbar-dark .nav-item .nav-link.filled-btn {
//   background-color: $primary-color !important;
//   font-weight: 500;
//   font-size: 15px;
//   letter-spacing: 1px;
//   border-radius: 32.8px;
//   border: 1px solid $primary-color;
//   padding: 10px 0px;
//   margin: 24px 0px;
//   color: #fff !important;
//   text-transform: capitalize;
//   width: 150px !important;
//   font-family: "Rubik-Bold",sans-serif ;
// }

.navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
}



.navbar-dark .navbar-brand img {
  margin-right: 35px;
}

.navbar-dark .navbar-nav .nav-link {
  color: $secondary-color !important;
}

.navbar.navbar-dark.top-nav-collapse.bg-primary .navbar-nav .nav-item>.cs-outline {
  border: 1px solid white !important;
}

.navbar-dark .navbar-nav a.nav-link.active:after {
  width: 50%;
  display: blocks;
  content: "";
  border-bottom: 0px solid #764200;
  position: absolute;
  bottom: 0px;
  right: 25%;
}

.navbar-dark.top-nav-collapse.bg-primary .navbar-toggler-icon {
  filter: brightness(15.5);
}

.navbar .nav-item .nav-link {
  display: block;
  font-size: 16px;
  //font-weight: bold;
  font-family: "Rubik-Bold", sans-serif !important;
  //letter-spacing: 1px;
  // text-transform: uppercase;
  margin: 0px 15px 0px 15px;
  border: 1px solid transparent;

  &:hover {
    color: $primary-color !important;
    border-bottom: 1px solid $primary-color;
  }
}

.md-form input:not([type]):focus:not([readonly]),
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="phone"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #ced4da;
  box-shadow: 0 0px 0 0 #4285f4;
}

.add-btn {
  font-size: 15px;
  color: #2484c6;
}

#homepage {
  overflow: hidden;
  // height: 100vh;

  #home {
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .row.left-to-right {
    margin: 0 10px;
  }

  .container {
    margin: 0;
    padding: 0;


    @media screen and (max-width:576px) {
      width: 100%;
      height: 100vh;
    }
  }

  .cs-outline {
    border: 1px solid #764200;
    border-radius: 25px;
    padding: 0px 20px !important;
    margin-top: 8px !important;
  }

  section h1 {
    color: $primary-color;
    font-weight: 600;
    // text-transform: uppercase;
  }

  section h2 {
    color: $primary-color;
    font-weight: normal;
    // text-transform: uppercase;
    margin-bottom: 60px;
    // font-size: 36px;
    font-family: "Rubik-Black", sans-serif;
  }

  .smiley-bg {
    // background-image: url("./assets/home/bg.png");
    background-size: cover;
    background-position: center;
    // overflow: hidden !important;
    // height: 200px;
    border-radius: 0 0 20px 20px;
    margin: 0 auto;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    // align-items: end;
    box-shadow: none !important;


  }

  .left-image-container {
    position: absolute;
    top: 110px;
    right: 70px;
    // z-index: 11111;
    cursor: pointer;
  }

  .right-image-container {
    position: absolute;
    top: 110px;
    left: 70px;
    // z-index: 11111;
    cursor: pointer;
  }

  .home-title {
    text-align: left;
    font-weight: 700;
    font-family: "Rubik-Black", sans-serif;
    font-size: 39px !important;
    // font-style: italic;
  }

  .home-sub-title {
    color: $primary-color;
    text-align: left;
    margin: 10px 0px 20px 0;
    font-family: "Rubik-Medium", sans-serif;

    text-transform: capitalize;
  }

  .home-desc {
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    width: 70%;
    color: #707070;

  }

  .home-action {
    position: relative;
  }

  // .home-action img {
  //   position: absolute;
  //   top: 2vh;
  //   left: 21vh;
  // }

  .cs-btn {
    color: white !important;
    border: 1px solid #764200;
    border-radius: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    width: 170px;
    display: block;
    background: #764200;
    margin: 10px 0px;
  }

  .cs-btn:hover {
    color: white !important;
    background-color: #2484c6;
    border: 1px solid #2484c6;
  }

  .cs-btn-outline {
    color: #764200 !important;
    border: 1px solid #764200;
    border-radius: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    width: 170px;
    display: block;
    margin: 10px 0px;
  }

  .cs-btn-outline:hover {
    color: white !important;
    border: 1px solid white;
    background-color: #764200;
  }

  .plan {
    height: 220px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.12);
  }

  .plan-cost {
    color: #764200;
    font-weight: 600;
  }

  .plan-period {
    color: #2484c6;
    font-weight: 400;
    border-bottom: 2px solid;
    display: block;
    width: 45px;
    padding: 20px 0;
    margin: 0 auto;
  }

  .plan-subscribe-btn {
    color: #764200 !important;
    border: 1px solid #764200;
    border-radius: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    width: 170px;
    display: block;
    margin: 15px auto;
  }

  .plan-subscribe-btn:hover {
    color: white !important;
    border: 1px solid white;
    background-color: #764200;
  }

  .plan-extra {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    color: grey;
    margin: 10px auto;
    display: block;
  }

  .features-title {
    color: #764200;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    margin: 30px;

  }

  .features-img {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    padding: 20px 0px;
    box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.12);
  }

  .features-img:hover {
    background: $primary-color;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .features-desc {
    font-size: 16px;
    text-align: center;
    color: black;
    line-height: 1.58;
    font-weight: normal;
    font-family: "Rubik-Bold", sans-serif;

  }

  #contact {

    .contact-us .row .col-md-6,
    .contact-us .row .col-md-12 {
      padding-left: 0px !important;
      padding-right: 10px;
    }

    .contact-us .row .col-md-12 .contact-btn {
      margin: 0;
    }

    .contact-btn {
      border-radius: 30px !important;
      width: 170px !important;
      background: #764200 !important;
      font-weight: 700 !important;
    }

    .contact-btn:hover {
      background: #2484c6 !important;
    }

    .md-form {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    .md-form input[type="text"]:not(.browser-default) {
      background: white;
      padding: 15px;
      box-sizing: border-box;
      border: 0px;
      border-radius: 25px;
    }

    .md-form input[type="number"]:not(.browser-default) {
      background: white;
      padding: 15px;
      box-sizing: border-box;
      border: 0px;
      border-radius: 25px;
    }

    .md-form textarea.md-textarea {
      background: white;
      padding: 15px;
      box-sizing: border-box;
      border: 0px;
      resize: none;
      border-radius: 25px;
    }
  }

  .cs-copyrights {
    // background: #2484c6;
    // background-image: url("././assets/home/footer-bg-blue.svg");
    background-color: $secondary-color;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 14px;
    padding: 12px 0px;
    /* position: absolute;
  bottom: 0px; */
  }

  .cs-copyrights span {
    margin: 0 auto;
  }

  .contact-us {
    margin-bottom: 20px;
  }

  img.download-app1 {
    width: 200px;
  }

  img.download-app2 {
    width: 180px;
  }

  .md-form .cs-rounded-input {
    border: 1px solid #9b9b9b;
    border-radius: 35px;
    padding: 10px 20px 10px 80px;
    color: #3c3c3b;
    height: 30px;
    width: 67%;
    background-color: white;
  }

  @media screen and (max-width: 768px) {
    // .smiley-bg {
    //   padding: 30px;
    // }

    .home-action img {
      position: absolute;
      top: 3vh;
      left: 25vh;
    }

    img.download-app1 {
      width: 100px;
    }

    img.download-app2 {
      width: 90px;
    }

    .col-xs-0,
    .col-sm-0,
    .col-md-0 {
      display: none;
    }
  }
}

#innernav {
  .green-bg {
    /* height: 70px; */
    background-image: url("./assets//inner/top-bar.png");
    height: 134px;
    /* position: inherit; */
    padding: 15px;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 100%;
  }

  .mid-icons .col-lg-3 {
    padding-right: 0px;
  }

  .mid-icons {
    .nav-link {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .icons {
    width: 60px;
    float: right;
  }

  .nav-link {
    display: block;
  }

  .sign {
    background-image: url("./assets/inner/signatures.svg");
    width: 70px;
    height: 80px;
    background-size: cover;
    margin: auto;
    background-position: top center;
  }

  .sign:hover,
  .nav-link.active .sign {
    background-image: url("./assets/inner/signature_active.svg");
    width: 70px;
    height: 80px;
    background-size: cover;
    margin: auto;
    background-position: top center;
  }

  .group {
    background-image: url("./assets/inner/groups.svg");
    width: 70px;
    height: 80px;
    background-size: cover;
    margin: auto;
    background-position: top center;
  }

  .group:hover,
  .nav-link.active .group {
    background-image: url("./assets/inner/groups_active.svg");
    width: 70px;
    height: 80px;
    background-size: cover;
  }

  .control {
    background-image: url("./assets/inner/controls.svg");
    width: 70px;
    height: 80px;
    background-size: cover;
    margin: auto;
    background-position: top center;
  }

  .control:hover,
  .nav-link.active .control {
    background-image: url("./assets/inner/controls_active.svg");
    width: 70px;
    height: 80px;
    background-size: cover;
  }

  .nav-link {
    img {
      margin: 0 10px;
    }
  }

  // .enterprise {
  //   background-image: url("./assets/inner/enterprise.png");
  //   width: 70px;
  //   height: 80px;
  //   background-size: cover;
  // }

  // .enterprise:hover {
  //   background-image: url("./assets/inner/enterprise_active.png");
  //   width: 70px;
  //   height: 80px;
  //   background-size: cover;
  // }
  .icon-title {
    font-size: 11px;
    color: #f0f9ee7d;
    text-transform: uppercase;
    margin-top: 5px;
    font-weight: 400;
    text-align: center;
    //margin-right: 30px;
  }

  .nav-link.active .icon-title {
    color: white;
  }

  .sides {
    cursor: pointer;
  }

  .sides:hover {
    filter: brightness(1.2);
  }

  // .first:before {
  //   content: "";
  //   display: block;
  //   width: 25.5%;
  //   height: 60px;
  //   margin: 0 auto;
  //   background-color: #2484c6;
  //   border-radius: 26px;
  //   margin-top: -55px;
  // }

  // .last:after {
  //   content: "";
  //   display: block;
  //   width: 25.5%;
  //   height: 60px;
  //   margin: 0 auto;
  //   background-color: #2484c6;
  //   border-radius: 26px;
  //   margin-top: -55px;
  // }

  // .green-bg::after {
  //   content: "";
  //   display: block;
  //   width: 25.5%;
  //   height: 60px;
  //   margin: 0 auto;
  //   background-color: #2484c6;
  //   border-radius: 26px;
  //   margin-top: -55px;
  // }

  // .mid-icons::after::before{

  //   content: "";
  //   display: block;
  //   width: 100%;
  //   height: 84px;
  //   background-color: #2484c6;
  //   border-radius: 26px;
  // }

  // .mid-icons::after::after{

  //   content: "";
  //   display: block;
  //   width: 100%;
  //   height: 84px;
  //   background-color: #2484c6;
  //   border-radius: 26px;
  // }
}

#innerpage {
  background-image: url("./assets/inner/smiley-bg.png");
  background-size: cover;
  position: absolute;
  min-height: 100%;
  width: 100%;

  .dashboard-body {
    padding: 30px;
  }

  .inner-page-title {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "Rubik-Bold", sans-serif !important;
  }

  //configuration page
  .config-item {
    width: 100%;
    text-align: left;
    background: white;
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 12px;
    position: relative;
    height: 70px;
    cursor: pointer;
    color: black;

  }

  .config-item:hover,
  .config-item:focus {
    background-color: #2484c6;
    color: white;
  }

  .overflow-auto-260px {
    max-height: 260px;
    overflow-y: auto;
  }

  .config-item:hover i.config-item-icon,
  .config-item:hover span,
  .config-item:focus i.config-item-icon {
    color: white;
  }

  h3.config-item-title {
    font-weight: 400;
    font-size: 15px;
  }

  p.config-item-desc {
    font-size: 13px;
  }

  .wizard-signature-style {
    transition: all 0.5s ease;
    background-color: white;
    margin: 0;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: #2484c6;

      .isDefault.fa.fa-check {
        background-color: white;
        color: #2484c6;
      }
    }

    .isDefault.fa.fa-check {
      transition: all 0.5s ease;
      padding: 7px;
    }
  }

  i.config-item-icon {
    position: absolute;
    top: 26px;
    right: 20px;
    font-size: 21px;
    color: #2484c6;
  }

  img.config-item-icon {
    position: absolute;
    top: 30px;
    right: 20px;
  }

  .separator40px {
    height: 40px;
    width: 100%;
  }

  .fullwidth70h {
    width: 100%;
    font-size: 15px;
    height: 70px;
    padding: 25px;
    font-weight: 400;
  }

  .fullwidth70h p.mini {
    font-size: 13px;
  }

  .logout-btn {
    color: #2484c6;
    padding: 0px;
    font-weight: 500;

  }

  .light-bg {
    background-color: rgba(210, 233, 253, 0.41);
    color: #2484c6;
  }

  //blacklist
  .blacklist-item {
    width: 100%;
    text-align: left;
    background: white;
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 12px;
    position: relative;
    height: 70px;
    cursor: pointer;
    color: grey;
  }

  .blacklist-img {
    width: 50px;
    display: inline-block;
    vertical-align: top;
  }

  .blacklist-name {
    font-size: 15px;
    margin: 0px 15px;
    font-weight: 400;
    color: #3c3c3b;
    display: inline-block;
  }

  .blacklist-number {
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    display: inline-block;
  }

  .blacklist-delete {
    color: #2484c6;
    float: right;
    font-size: 18px;
  }

  .blacklist-add-item {
    color: #2484c6;
    font-size: 20px;
    font-weight: 400;
  }

  .bottom-note {
    opacity: 0.8;
    font-size: 13px;
    font-weight: normal;
    color: #666666;
    margin-top: 20px;
    text-align: left;
    padding: 0px 8px;
  }

  //material table override
  .MuiIcon-root {
    color: #2484c6;
  }

  .MuiTableRow-root {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }

  .MTableToolbar-spacer-7 {
    display: none;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .MuiTableRow-head {
    display: none;
  }

  .bottom {
    color: #2484c6;
    font-size: 17px;
  }

  .bottom:hover {
    color: #2484c6;
    font-size: 17px;
    background: white;
  }

  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone .MuiIconButton-root {
    padding: 12px 12px;
  }

  .dates-label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  input.flatpickr-input {
    border: 0px;
    padding: 12px;
    color: #2484c6;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 40px;
  }

  .flatpickr-months .flatpickr-month {
    background: #2484c6 !important;
  }

  // .flatpickr-day.selected,
  // .flatpickr-day.selected:focus,
  // .flatpickr-day.selected:hover,
  // .flatpickr-day.selected.prevMonthDay,
  // .flatpickr-day.selected.nextMonthDay {
  //   background: #2484c6 !important;
  //   border-color: #2484c6 !important;
  // }

  .days {
    display: flex;
    margin-bottom: 50px;
  }

  .day {
    width: 125px;
    padding: 10px;
    background-color: white;
    color: #2484c6;
    font-size: 16px;
    text-align: center;
    border-radius: 35px;
    margin-right: 10px;
    box-shadow: 0px 0px 1px #c5c1c1;
    cursor: pointer;
    font-weight: 400;
  }

  .day:hover {
    background-color: #2484c6;
    color: white;
  }

  .day.selected-day {
    background-color: #2484c6;
    color: white;
  }

}

#cs_modal {
  p.home-sub-label {
    text-align: left;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 0;
    font-family: "Rubik-Bold", sans-serif;
  }

  .phone-input {
    border-left: none !important;
    border-top: 1px solid #9b9b9b !important;
    border-right: 1px solid #9b9b9b !important;
    border-bottom: 1px solid #9b9b9b !important;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 10px 20px 10px 20px;
    color: #3c3c3b;
    height: 30px;
    width: 80%;
    margin-left: 43px;
    outline: none;
  }

  .phone-input:focus {
    box-shadow: none;
  }

  .blackList-phone-input {
    border-left: none !important;
    border-top: 1px solid #9b9b9b !important;
    border-right: 1px solid #9b9b9b !important;
    border-bottom: 1px solid #9b9b9b !important;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 10px 20px 10px 20px;
    color: #3c3c3b;
    height: 46px;
    width: 86%;
    outline: none;
  }

  .blackList-phone-input:focus {
    box-shadow: none;
  }

  //popups styles

  .modal-dialog {
    max-width: 430px;
  }

  .modal-dialog .modal-content {
    border-radius: 10px;
  }

  #chooseplan {
    .modal-content {
      background-image: linear-gradient(#2484c6, #f9e329);
    }

    h3,
    h6,
    p {
      color: white;
    }

    input {
      cursor: pointer;
    }

    button.close {
      font-size: 25px;
      color: white;
      font-weight: lighter;
      opacity: 1;
    }

    .plans {
      color: white;
      background: #3c3c3b21;
      font-size: 15px;
      border-radius: 15px;
      padding: 50px 0px;
      margin-bottom: 20px;
      cursor: pointer;
      height: 110px;
    }

    // .one-plan {
    //   opacity: 0.5;
    // }

    // .one-plan:hover {
    //   opacity: 1;
    // }

    .plans:hover,
    .plans.selected {
      background: white;
      color: black;
      opacity: 1;
    }

    // .plans:hover .plan-price {
    //   opacity: 1;
    // }
    // .plans .fa-check {
    //   display: none;
    // }

    // .plans.selected .fa-check {
    //   display: inline-block;
    //   color: #67bd47;
    //   background: white;
    //   border-radius: 50%;
    //   padding: 8px;
    //   margin-left: 12px;
    //   font-size: 9px;
    // }

    .plan-price {
      cursor: pointer;
      background: #764200;
      color: white;
      border-radius: 15px;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding: 5px;
      position: absolute;
      top: -8px;
      width: 75%;
      left: 14px;
      opacity: 0.5;
    }

    .plan-price.selected {
      opacity: 1;
    }

    .currency {
      font-size: 9px;
      position: absolute;
      left: 58px;
      top: 10px;
    }
  }

  .modal-header {
    border-bottom: 0px solid #dee2e6;
    display: block;
    padding: 1rem 1rem 0px;

    h4 {
      text-align: right;
    }
  }

  button.close {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    opacity: 0.5;
    width: 35px;
    height: 35px;
    padding: 0px 8px 1px 9px;
    margin: 0px;
    font-size: 25px;
  }

  .modal-body {
    padding: 0px 55px 5px 55px;
  }

  .modal-body h3 {
    font-weight: 900;
    font-size: 28px;
    margin: 0px 20px 20px;
    color: $tertiary-color;
    font-family: "Rubik-Bold", sans-serif !important;
  }

  .modal-body h6 {
    font-weight: 400;
    font-size: 18px;
    color: #764200;

  }

  .modal-body span {
    color: #979598;
    font-size: 14px;
    font-family: "Rubik-Bold", sans-serif;
  }

  .modal-body h6:nth-of-type(2) {
    font-weight: 400;
    font-size: 15px;
    color: #979598;
  }

  .modal-body p {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    margin: 30px 0px;
    letter-spacing: 0px;
    color: #3c3c3b;

  }

  .modal-body span a {
    color: #764200;
    text-align: center;
    font-size: 12px;
    font-weight: 400;

  }

  .response {
    font-size: 14px;
    font-weight: 400;
    color: #764200;
  }

  .sub-note {
    font-size: 14px;
    font-weight: 400;
    margin: 50px 0px 30px 0px;
    display: block;
  }

  span.forget-pass {
    font-size: 12px;
    margin-top: -15px;
    float: right;
    cursor: pointer;
    color: #764200;
    text-decoration: underline;
  }

  .forget-pass a {
    cursor: pointer;
  }

  span.sub-note a {
    color: #764200;
  }

  .filled-btn {
    width: 100%;
    background-color: $primary-color !important;
    // font-weight: 700;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 32.8px;
    font-family: "Rubik-Bold", sans-serif;
    text-transform: capitalize;
    // margin-bottom: 25px;
    padding: 15px 0px;
    margin: 24px 0px;
  }

  .outline-btn {
    width: 100%;
    border: 1px solid $primary-color !important;
    color: $primary-color;
    font-weight: 700;
    background-color: white !important;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 32.8px;
    text-transform: capitalize;
    //padding: 18px 0px;
    margin-bottom: 20px;
    font-family: "Rubik-Bold", sans-serif !important;
  }

  .less-padding-margin {
    padding: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #verify {
    .md-form {
      display: inline-flex;
    }
  }

  #bwalletOTP {
    .md-form {
      display: inline-flex;
    }
  }

  .round {
    width: 40px;
    height: 40px;
    border: 1px solid #9b9b9b;
    border-radius: 50%;
    padding: 0;
    color: #3c3c3b;
    margin-right: 10px;
    text-align: center;
  }

  .rectangle {
    width: 45px;
    height: 55px;
    border: 0px solid #9b9b9b;
    border-radius: 36px;
    padding: 0;
    color: #3c3c3b;
    margin-right: 10px;
    text-align: center;
    background: #f4f4f8;
    font-weight: bold;
    font-size: 21px;
  }

  .full-rectangle {
    border: 0px solid #9b9b9b;
    border-radius: 13px;
    padding: 0 10px;
    color: #3c3c3b;
    margin-right: 10px;
    text-align: left;
    background: #f4f4f8;
    font-weight: bold;
    font-size: 21px;
    width: 100%;
    height: 50px;
  }

  .full-rectangle::placeholder {
    color: #bec0c1;
    font-size: 15px;
    font-weight: 300;
  }

  .cs-not-rounded-btn {
    width: 100%;
    background-color: #764200 !important;
    // font-weight: 700;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 12px;
    padding: 15px 0px;
    margin: 24px 0px;
    text-transform: capitalize;
    font-weight: 200;
  }

  .cs-rounded-input {
    border: 1px solid #9b9b9b;
    border-radius: 35px;
    padding: 10px 20px 10px 20px;
    color: #3c3c3b;
    height: 30px;
    width: 85%;
  }

  .cs-rounded-input:focus {
    box-shadow: none;
  }

  .blacklist-rounded-input {
    border: 1px solid #9b9b9b;
    border-radius: 35px;
    padding: 10px 20px 10px 60px;
    color: #3c3c3b;
    height: 30px;
    width: 80%;
  }

  .blacklist-rounded-input:focus {
    box-shadow: none;
  }

  .ind-rounded-input {
    border: 1px solid #9b9b9b;
    border-radius: 35px;
    padding: 10px 20px 10px 60px;
    color: #3c3c3b;
    height: 30px;
    width: 80%;
  }

  .ind-rounded-input:focus {
    box-shadow: none;
  }

  p.error,
  span.resend {
    font-weight: 500;
    font-size: 13px;
    color: #764200;
  }
}

#verify .modal-dialog,
#verifyReset .modal-dialog {
  max-width: 600px;
}

#verifyReset {
  .md-form:first-of-type {
    display: block;
  }

  .md-form {
    display: inline-flex;
  }
}

#filtertags {

  .card,
  .card-body,
  .container {
    padding: 0px;
  }

  .filterDiv {
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    padding: 10px 20px;
    cursor: pointer;
    display: none;
  }

  .filterDiv:hover {
    background-color: #2484c6;
    color: white;
    cursor: pointer;
  }

  .selected-template {
    background-color: #2484c6;
    color: white;
  }

  .show {
    display: block;
  }

  #myBtnContainer {
    border-bottom: 1px solid #eee;
    padding: 10px 10px;
  }

  /* Style the buttons */
  .btn {
    border: none;
    outline: none;
    padding: 8px 12px;
    background-color: #eeeeee;
    cursor: pointer;
    color: #9b9b9b;
    border-radius: 25px;
    font-weight: 600;
    font-size: 12px;
  }

  .btn:hover {
    background-color: #ddd;
  }

  .btn.active-group {
    background-color: #764200;
    color: white;
  }

  li:hover {
    background-color: #eeeeee;
  }
}

#wizard {
  width: 100%;

  .steps-form .steps-row:before {
    height: 0px;
  }

  .steps-form .steps-row .steps-step {
    display: inline-table;
  }

  .steps-step.mid a:before {
    height: 0px;
  }

  //line through steps numbers
  .steps-step.mid.active a:before {
    top: 15px;
    bottom: 0;
    left: -10px;
    position: absolute;
    content: " ";
    width: 10%;
    height: 2px;
    border-radius: 84px;
    background-color: #2484c6;
  }

  .steps-step.mid a:after {
    top: 15px;
    bottom: 0;
    left: 24px;
    position: absolute;
    content: " ";
    width: 95%;
    height: 2px;
    border-radius: 84px;
    background-color: white;
  }

  .steps-step.mid.active a:after {
    height: 0px;
  }

  .steps-step.active a:after {
    content: "";
    height: 0px;
  }

  .steps-step.last.active a:before {
    top: 15px;
    bottom: 0;
    left: -50px;
    position: absolute;
    content: " ";
    width: 30%;
    height: 2px;
    border-radius: 84px;
    background-color: #2484c6;
  }

  // progress bars below steps numbers
  .steps-form .steps-row .steps-step:before {
    top: 65px;
    bottom: 0;
    left: 10px;
    position: absolute;
    content: " ";
    width: 95%;
    height: 5px;
    border-radius: 84px;
    background-color: #2484c659;
  }

  .steps-form .steps-row .steps-step.active:before {
    top: 65px;
    bottom: 0;
    left: 10px;
    position: absolute;
    content: " ";
    width: 95%;
    height: 5px;
    border-radius: 84px;
    background-color: #2484c6;
  }

  .btn.Ripple-parent.btn-circle.btn-cs-green:after {
    top: 90px;
    bottom: 0;
    left: 10px;
    position: absolute;
    content: " ";
    width: 95%;
    height: 5px;
    border-radius: 84px;
    background-color: green;
  }

  .btn-cs-green {
    margin-left: auto;
    display: block;
    background-color: #2484c6;
    color: white;
    font-weight: 700;
    z-index: 100;
  }

  .btn-cs-default {
    background-color: white;
    color: #9b9b9b;
    font-weight: 700;
  }

  .steps-form .steps-row .steps-step p {
    position: absolute;
    top: 0px;
    left: 47px;
    font-size: 16px;
    font-weight: 400;
    color: #3c3c3b;
    margin-bottom: 0px;
  }

  .steps-form .steps-row .steps-step span {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: #666666;
    margin-top: -6px;
    display: block;
    margin-left: 45px;
    text-align: left;
  }

  .steps-step a {
    display: flex;
  }

  .wizard-filledbtn {
    background: #764200 !important;
    width: 100%;
    max-width: 250px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .wizard-outlinebtn {
    border: 1px solid #764200 !important;
    color: #764200;
    background-color: white !important;
    width: 100%;
    max-width: 250px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .tick-mark {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 24px;
    z-index: 1200;
    top: -4px;
  }

  .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
    max-height: 250px;
    overflow-y: scroll;
  }
}

.MuiTableRow-root {
  // .MuiSvgIcon-root {
  //   display: none;
  // }

  .MuiIconButton-label {
    input {
      display: block;
      opacity: 0;
      position: absolute;
      width: auto;
      height: auto;
      pointer-events: all;
      z-index: 12;
      top: 20px;
      left: 17px;
    }
  }
}

.card-header h3 {
  font-size: 18px;
  color: #3c3c3b;
  font-weight: 500;
  text-align: start;
  font-family: "Rubik-Medium", sans-serif;
}

.card-header.active h3 {
  color: #2484c6;
}

.card-header h3 i {
  color: #2484c6;
}

.collapse .card-body {
  background-color: #2485c61d;
}

.collapse .card-body p {
  color: #707070;
  font-size: 16px;
}

.fileContainer {
  background: #d2d2d2 !important;
  padding: 0px !important;
  height: auto;
  min-height: 100px;
  max-height: 350px;
}

.fileContainer .chooseFileButton {
  padding: 0px !important;
  font-size: 21px !important;
  background: white !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  color: #764200 !important;
  position: absolute;
  box-shadow: 1px 1px 14px 4px black;
  z-index: 12;

  &:hover {
    background: #cccccc80 !important;
  }
}

.fileContainer .uploadPictureContainer {
  width: 100% !important;
  padding: 0px !important;
  background: none !important;
  margin: 0px !important;
  border: 0px solid #d0dbe4 !important;
  border-radius: 15px;

  img {
    border-radius: 10px;
  }
}

.fileContainer .deleteImage {
  display: none !important;
}

.errorMessage {
  margin-top: 130px;
  font-size: 14px;
  font-weight: bolder;
}

.fa.fa-chevron-right {
  color: #2484c6;
}

.back,
.back:hover {
  color: #764200;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
  padding: 0;
  margin-bottom: 20px;


  .fa.fa-chevron-right {
    color: #764200 !important;
  }
}

.dashboard-foot .cs-btn-outline {
  background: transparent !important;
  color: #764200;
  border: 1px solid;
  margin: 30px auto;
  width: 260px;
  border-radius: 7px;
  font-weight: 500;
}

.MuiTableCell-body {
  max-width: 200px !important;
  word-break: break-word !important;
}

// .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
//   max-height: 400px;
//   overflow-y: scroll;
// }

.Component-horizontalScrollContainer-12 {
  overflow-x: auto;
  position: relative;
  max-height: 400px;
  overflow-y: scroll;
  height: 250px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary-color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary-color;
}

#filtertags {
  max-height: 450px;
  overflow: scroll;
}

.plan-img-title {
  display: block;
  color: white;
  margin: 10px 0px;
  padding-bottom: 15px;
  font-size: 14px;
  width: 100%;
  border-bottom: 1px solid white;
}

#update-img {
  img {
    box-shadow: 1px 1px 20px 1px black;
    border-radius: 10px;
  }

  .fileContainer {
    // background: #fff;
    z-index: 10;
    box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    background: transparent !important;
    top: 100%;
    left: 57%;
    transform: translate(-50%, -60%);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

.m-0-md-form .md-form {
  margin: 0 20px 0 0 !important;
}

.freq-count {
  top: 22px;
  float: right;
  color: #2484c6;
  font-size: 21px;
  font-weight: 600;
  position: absolute;
  right: 22px;
}

.via {
  font-size: 14;
  font-weight: 500;
  color: #764200;
  margin-right: 20px;
  margin-top: 2px;
  float: right;
}

.home-action>.nav-link {
  padding: 0 !important;
}

#terms {
  .cs-copyrights {
    background: #2484c6;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 14px;
    padding: 12px 0px;
    position: absolute;
    bottom: 0px;
  }
}

.add-new {
  margin-top: 32px;
}

.background-transparent {
  border-radius: 25px;
  background-color: transparent !important;
}

i.fa-plus {
  background: #764200;
  color: white;
  padding: 6px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 12px;
}

i.fa-minus {
  background: #2484c6;
  color: white;
  padding: 6px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 12px;
}

#numbersList {
  height: 200px;
  overflow: scroll;
}

// .sign-tb td:last-child {
//   width: 180px !important;
// }

// .setdefault-div {
//   position: relative;
//   top: 64px;
//   left: 110px;
// }

.default-sbtn {
  // box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 0px;
  border-radius: 18px;
  border: 1px solid #2484c6;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  // margin-top: 5px;
}

#errors {
  font-size: 13px;
  padding-bottom: 5px;
  text-align: left;
  padding-left: 5px;
}

#contactSupport {

  .row .col-md-6,
  .row .col-md-12 {
    padding-left: 0px !important;
    padding-right: 10px;
  }

  .row .col-md-12 .send-btn {
    margin: 0;
  }

  h4 {
    color: #2484c6;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 28px;
  }

  .send-btn {
    border-radius: 30px !important;
    width: 170px !important;
    background: #2484c6 !important;
    font-weight: 700 !important;
  }

  .send-btn:hover {
    border-radius: 30px !important;
    width: 170px !important;
    background: #764200 !important;
    font-weight: 700 !important;
  }

  .close-btn {
    border-radius: 30px !important;
    width: 170px !important;
    background: #2484c6 !important;
    font-weight: 700 !important;
  }

  .close-btn:hover {
    border-radius: 30px !important;
    width: 170px !important;
    background: #764200 !important;
    font-weight: 700 !important;
  }

  .md-form {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .md-form input[type="text"]:not(.browser-default) {
    background: white;
    padding: 15px;
    box-sizing: border-box;
    border: 0px;
    border-radius: 25px;
  }

  .md-form input[type="number"]:not(.browser-default) {
    background: white;
    padding: 15px;
    box-sizing: border-box;
    border: 0px;
    border-radius: 25px;
  }

  .md-form textarea.md-textarea {
    background: white;
    padding: 15px;
    box-sizing: border-box;
    border: 0px;
    resize: none;
    border-radius: 25px;
  }
}

.closeBtn {
  border-radius: 30px !important;
  width: 170px !important;
  background: #2484c6 !important;
  font-weight: 700 !important;
}

.closeBtn:hover {
  border-radius: 30px !important;
  width: 170px !important;
  background: #764200 !important;
  font-weight: 700 !important;
}

.proceedBtn {
  border-radius: 30px !important;
  width: 170px !important;
  background: #2484c6 !important;
  font-weight: 700 !important;
}

.proceedBtn:hover {
  border-radius: 30px !important;
  width: 170px !important;
  background: #764200 !important;
  font-weight: 700 !important;
}

.fa.fa-check {
  // margin-right: 40px;
  font-size: 18px;
}

#contact-modal,
#activate-modal {
  .modal-dialog {
    max-width: 430px;
  }

  .modal-dialog .modal-content {
    border-radius: 10px;
  }

  button.close {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    opacity: 0.5;
    width: 35px;
    height: 35px;
    padding: 0px 8px 1px 9px;
    margin: 0px;
    font-size: 25px;
  }

  .modal-header {
    border-bottom: 0px solid #dee2e6;
  }

  .modal-body {
    padding: 0px 55px 5px 55px;
  }

  .modal-body h3 {
    font-weight: 700;
    font-size: 19px;
    margin: 20px;
  }

  .modal-body h6 {
    font-weight: 400;
    font-size: 18px;
    color: #764200;
  }

  .modal-body h6:nth-of-type(2) {
    font-weight: 400;
    font-size: 15px;
    color: #979598;
  }

  .modal-body p {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    margin: 30px 0px;
    letter-spacing: 0px;
    color: #3c3c3b;
  }

  .modal-body span a {
    color: #8e959b;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }

  .response {
    font-size: 14px;
    font-weight: 400;
    color: #764200;
  }

  .cs-outline {
    border: 1px solid #764200;
    border-radius: 25px;
    padding: 0px 20px !important;
    margin-top: 8px !important;
  }

  .cs-btn {
    color: white !important;
    border: 1px solid #764200;
    border-radius: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    width: 170px;
    display: block;
    background: #764200;
    margin: 10px 0px;
  }

  .cs-btn:hover {
    color: white !important;
    background-color: #2484c6;
    border: 1px solid #2484c6;
  }

  .cs-btn-outline {
    color: #764200 !important;
    border: 1px solid #764200;
    border-radius: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    width: 170px;
    display: block;
    margin: 10px 10px;
  }

  .cs-btn-outline:hover {
    color: white !important;
    border: 1px solid white;
    background-color: #764200;
  }

  .cs-rounded-input {
    border: 1px solid #9b9b9b;
    border-radius: 35px;
    padding: 10px 20px 10px 20px;
    color: #3c3c3b;
    height: 30px;
    width: 86%;
  }

  .cs-rounded-area {
    border: 1px solid #9b9b9b;
    border-radius: 35px;
    padding: 10px 20px 10px 20px;
    color: #3c3c3b;
    width: 95%;
  }

  .cs-rounded-input:focus {
    box-shadow: none;
  }

  .md-form {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
}

.isDefault.fa.fa-check {
  margin-right: 40px;
  font-size: 14px;
  border: 1px solid #2484c6;
  border-radius: 50%;
  padding: 10px;
  background: #2484c6;
  color: white;
}

@media only screen and (max-width: 426px) {
  .dashboard-mid {
    width: 100%;
    margin: 0;
  }

  .dashboard-top {
    width: 100%;
  }

  .navbar {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .round {
    width: 32px !important;
    height: 32px !important;
    margin-right: 2px !important;
  }

  .float-fixed-left {
    float: left;
    position: fixed;
    top: -18px;
    left: 10px;
  }

  .float-fixed-right {
    float: right;
    position: fixed;
    top: 35px;
    right: 35px;
  }

  #innerpage {
    .config-item {
      height: 90px;
    }
  }

  #innernav {
    width: 100%;

    .pt-none {
      padding-top: 0;
    }

    .green-bg {
      height: 60px;
      padding: 0;
    }

    .sides {
      width: 40px;
    }

    .logo {
      img {
        margin: 0;
        width: 25px;
      }
    }

    .control,
    .nav-link.active .control {
      width: 30px;
      height: 50px;
    }

    .group,
    .nav-link.active .group {
      width: 30px;
      height: 50px;
    }

    .sign,
    .nav-link.active .sign {
      width: 30px;
      height: 50px;
    }

    .icon-title {
      display: none;
    }
  }

  .container.dashboard-body {
    padding: 16px 0 30px 0 !important;
  }

  .days {
    display: flex;
    flex-wrap: wrap;

    span {
      margin: 5px 5px 5px 0 !important;
    }
  }

  .steps-form {
    zoom: 0.75;
  }

  .section {

    padding: 0px 16px 0 16px;
  }

  .MuiTableCell-paddingNone {
    padding: 16px !important;
  }

  .fileContainer {
    overflow: hidden;
    width: 95%;

    input[type="file"] {
      width: 260px;
    }
  }

  .mt-86 {
    margin-top: 86px;
  }

  .cs-rounded-input {
    width: 85% !important;
  }

  #homepage .md-form .cs-rounded-input {
    width: 66% !important;
  }

  .filled-btn {
    margin: 0 0 20px;
  }

  .MuiTableCell-body {
    width: auto !important;
  }

  #cs_modal {
    .phone-input {
      border-left: none !important;
      border-top: 1px solid #9b9b9b !important;
      border-right: 1px solid #9b9b9b !important;
      border-bottom: 1px solid #9b9b9b !important;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      padding: 10px 20px 10px 20px;
      color: #3c3c3b;
      height: 30px;
      width: 70%;
      margin-left: 43px;
      outline: none;
    }

    .phone-input:focus {
      box-shadow: none;
    }
  }
}

#gi,
#innerpage {
  td:last-child>div {
    display: block !important;
    text-align: right;
  }
}

.form-control {
  box-sizing: content-box;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.input-group-text {
  background-color: #fff !important;
  border: 1px solid #9b9b9b;
  border-right: none;
  border-radius: 35px;
  padding: 10px 20px 10px 20px;
  color: #3c3c3b;
  vertical-align: middle;
}

.prepend-code {
  display: inline-block;
  height: 42px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  padding: 12px;
  border: 0px solid #9b9b9b;
  position: absolute;
  top: 27px;
  left: 3vh;
  font-weight: 400;
  color: #3c3c3b;
  background: white;
  z-index: 1;

}

.prepend-code-2 {
  display: inline-block;
  height: 42px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  padding: 12px;
  border: 0px solid #9b9b9b;
  position: absolute;
  top: 3px;
  left: 1vh;
  font-weight: 400;
  color: #3c3c3b;
  background: white;
  z-index: 1;
}

.MuiToolbar-root {
  display: inline-block !important;
}

img.download-from-store {
  width: 185px;
  height: 55px;
  margin: 15px 10px;
}

p.download-app {
  width: 100%;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  opacity: 0.5;

}

.contact-us {
  img {
    width: 50px;
    height: 50px;
  }

  h6 {
    width: 100%;
    text-align: left;
    margin: 10px 0px 0px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    height: 18px;
  }

  p {
    color: #fff;
    opacity: 1;
  }
}

#contact {
  padding: 35px 0px;

  .contact-us {
    span {
      color: #fff;
      // text-transform: uppercase;
      letter-spacing: -1px;
      font-size: 32px;
      font-family: "Rubik-Regular", sans-serif;
    }
  }
}

.cs-copyrights {
  a {
    color: #fff;
    padding: 10px 0px 0px 0px;
    font-size: 13px;
  }

  hr {
    width: 100%;
    height: 1px;
    /* color: white; */
    border-color: #ffffff4d;
  }

  .rights {
    color: #ffffff4d;
    font-size: 12px;
  }
}

.fixed-to-bottom {
  position: absolute !important;
  right: 20px;
  bottom: 20px;

  img {
    width: 35px;
  }
}

.md-tabs {
  padding: 5px;
  margin-bottom: 0px;
  background-color: #00000080;
  border-radius: 5px;
  box-shadow: 0 0px 0px 0 #000, 0 0px 0px 0 #000;
  transform: scale(0.7);

  .nav-item.open .nav-link {
    background-color: #00000080;
  }

  .nav-link.active {
    background-color: white;
    color: #764200;
  }
}

.section-term {
  min-height: 60vh;

  h1 {
    font-size: 1.5rem !important;
  }

  h2 {
    font-size: 20px !important;
  }
}

.tab-content {
  //padding: 0px 0px 45px 0px;
  padding: 0px;

  .plan {
    background-color: $primary-color;
    border-radius: 32.5px;
    color: white;
    padding: 15px 0px;
    margin: 0 auto;
    cursor: pointer;
    font-family: "Rubik-Bold", sans-serif;
    font-size: 14px;
  }
}

a.terms-link {
  color: #979598;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.btn,
.btn-hover {
  box-shadow: 0px 0px 0 #eee, 0 0px 0px 0 #eee !important;
}

.faq-title {
  color: #404040;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  font-family: "Rubik-Medium", sans-serif;
}

.faq-subtitle {
  color: #3b566e80;
  text-align: left;
  font-size: 13px;
}

.faqs {
  min-height: 60vh;

  .faq-title {
    color: #404040;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
  }

  .faq-subtitle {
    color: #3b566e80;
    text-align: left;
    font-size: 13px;
  }

  .card-header.active h3 {
    color: #3b566e;
  }

  .card-header {
    background-color: white;
    border-bottom: 0px solid rgb(0, 0, 0) !important;
  }

  .card-header h3 i,
  .fa.fa-chevron-right {
    color: #3b566e;
  }

  .collapse .card-body {
    background-color: white;
    color: #3b566e;
  }

  .collapse .card-body p {
    color: #3b566e;
  }

  hr {
    border-top: 0.3px solid #70707052;
    width: 95%;
    opacity: 0.3;
  }
}

#old_login {
  .modal-body h3 {
    text-transform: uppercase;
    font-size: 18px;

  }

  span.prepend-code {
    top: 2px !important;
    background: transparent;

    color: #bec0c1 !important;

  }

  .phone-input {
    border-left: 1px solid #9b9b9b !important;
    border-top: 1px solid #9b9b9b !important;
    border-right: 1px solid #9b9b9b !important;
    border-bottom: 1px solid #9b9b9b !important;
    // border-top-right-radius: 35px;
    // border-bottom-right-radius: 35px;
    //border-radius: 35px;
    padding: 10px 20px 10px 80px;
    //color: #3c3c3b;
    height: 30px;
    width: 69%;
    margin-left: 0px;
    outline: none;


    border-radius: 20px;
    background-color: #f4f4f8;
    border: none !important;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bec0c1 !important;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bec0c1 !important;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bec0c1 !important;
    }


  }

  #password {
    padding: 10px 20px 10px 32px;
    width: 84%;



    border-radius: 20px;
    background-color: #f4f4f8;
    border: none !important;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bec0c1 !important;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bec0c1 !important;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bec0c1 !important;
    }
  }
}

#verifyReset {
  span.prepend-code {
    top: 2px !important;
    background: transparent;
  }

  .phone-input {
    border-left: 1px solid #9b9b9b !important;
    border-top: 1px solid #9b9b9b !important;
    border-right: 1px solid #9b9b9b !important;
    border-bottom: 1px solid #9b9b9b !important;
    // border-top-right-radius: 35px;
    // border-bottom-right-radius: 35px;
    border-radius: 35px;
    padding: 10px 20px 10px 80px;
    color: #3c3c3b;
    height: 30px;
    width: 69%;
    margin-left: 0px;
    outline: none;
  }

  #password {
    padding: 10px 20px 10px 32px;
    width: 84%;
  }
}

p.error.red-error {
  color: red !important;
  margin: 14px !important;
}

.iphone-icons {
  width: 4vw;
}


//==============================================================================================================================
//==============================================================================================================================
//-----------------------------------------------ARABIC CSS STARTS HERE---------------------------------------------------------
//==============================================================================================================================
//==============================================================================================================================
.arabic-parent-styling {

  font-family: "NotoKufiArabic-Regular", sans-serif;

  .back {
    text-align: right !important;
  }

  #innerpage {
    .inner-page-title {
      text-align: right !important;
    }

    .config-item {
      text-align: right !important;
    }

    i.config-item-icon {
      left: 20px !important;
      right: auto !important;
    }

    .bottom-note {
      text-align: right !important;
    }
  }

  #contact-modal {
    .md-form {
      text-align: right !important;
    }
  }

  .contact-us {
    h6 {
      text-align: right !important;
    }
  }

  .fixed-to-bottom {
    right: auto !important;
    left: 20px !important;
  }

  #homepage {
    .home-title {
      text-align: right !important;
    }

    .home-sub-title {
      text-align: right !important;
    }

    .home-desc {
      text-align: right !important;
    }

  }

  #cs_modal {
    p.home-sub-label {
      text-align: right !important;
      font-family: "NotoKufiArabic-Bold", sans-serif !important;
    }


    .modal-body h3,
    .filled-btn,
    .outline-btn {
      font-family: "NotoKufiArabic-Bold", sans-serif !important;
    }

  }

  .faq-title {
    text-align: right !important;
  }

  .faq-subtitle {
    text-align: right !important;
  }

  .phone-input-fixed-ltr {
    direction: ltr !important;
  }

  .navbar-dark .navbar-brand img {
    margin-right: unset !important;
    margin-left: 35px !important;
  }

  .navbar-brand {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }

  .language-change-container {
    font-family: "Rubik-Bold", sans-serif !important;
    padding: 2px 2px;

    span {
      margin-bottom: 0px;
    }
  }

  .navbar .nav-item .nav-link,
  .home-title {
    font-family: "NotoKufiArabic-Black", sans-serif !important;
  }

  section h2 {
    font-family: "NotoKufiArabic-Black", sans-serif !important;
  }

  .inner-page-title {
    font-family: "NotoKufiArabic-Bold", sans-serif !important;
  }

  .home-sub-title,
  .faq-title {
    font-family: "NotoKufiArabic-Medium", sans-serif !important;
  }

  .features-desc {
    font-family: "NotoKufiArabic-Bold", sans-serif !important;
  }

  .contact-us span {
    font-family: "NotoKufiArabic-Regular", sans-serif !important;
  }

  .brown-regular-header-text {
    font-family: "NotoKufiArabic-Bold", sans-serif !important;
  }

  .black-light-body-text {
    font-family: "NotoKufiArabic-Bold", sans-serif !important;
  }


  .tab-content {
    .plan {
      font-family: "NotoKufiArabic-Black", sans-serif !important;
    }
  }

  .modal-body span {
    font-family: "NotoKufiArabic-Black", sans-serif !important;
  }

  .card-header h3 {
    font-family: "NotoKufiArabic-Bold", sans-serif !important;
  }
}

iframe {
  display: none;
}

.menu-card {
  background-color: white;
  height: 79px;
  width: 444px;
  box-shadow: none;
  border-radius: 10px;
  cursor: pointer;
}

.iphone-menu-card {
  background-color: white;
  height: 79px;
  width: 100%;
  box-shadow: none;
  border-radius: 10px;
  cursor: pointer;
}

.disabled-menu-card {
  opacity: 0.49;
  border-radius: 10px;
  box-shadow: 0 -1px 13px 0 rgba(98, 98, 98, 0.1);
  background-color: #fff;
  height: 79px;
  width: 444px;
  box-shadow: none;
  cursor: pointer;

  @media screen and (max-width:435px) {
    height: 79px;
  }
}

.imgs-card {
  // opacity: 0.32;
  // -webkit-filter: blur(50px);
  // filter: blur(50px);
  // background-color: rgba(166, 78, 254, 0.2);

  // height: 300px !important;
  width: 509px;
  box-shadow: none;
  height: 20vh;
  // margin-top: 70px;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  justify-content: center;
  align-self: center;
  // position: fixed;
  bottom: 0;
  background-color: transparent !important;
  // border: solid 0.1px #001427;

  @media screen and (max-width:435px) {
    width: 100%;
    height: auto;
    background-color: transparent !important;
    justify-content: center;
    align-self: center;
  }
}

.blue-text {
  font-size: 16px;
  font-weight: 500;
  color: #2d46d2 !important;

  @media screen and (max-width:435px) {
    font-size: 16px;
    width: 100px;
  }

  @media only screen and (min-width: 1080px) and (max-width:1440px) {
    font-size: 2vh;
  }
}

.blue-value {
  font-size: 20px;
  font-weight: bold;
  color: #00beee;
  font-family: 'Montserrat-Bold', sans-serif;
}

.gray-text {
  font-size: 26px;

  @media screen and (max-width:435px) {
    font-size: 16px;
    width: 100px;
  }
}

.titles {
  color: #1a446d;
  font-size: 15px;
  margin-top: -20px;
  font-family: 'Montserrat-SemiBold', sans-serif;


  @media screen and (max-width:435px) {
    font-size: 12px;
    // margin-top: -40px;
  }
}

.taste-img {
  position: absolute;
  // top: -150px;
  // right: 30px;
  z-index: 1 !important;
  border-radius: 90px;
  // box-shadow: rgba(211, 223, 237, 0.38);
  // box-shadow: 5px 5px 16px #f7f7f7cc inset;
}

.logo-card {
  justify-content: center;
  align-items: center;
  margin-bottom: 7%;
}


.qcheck-logo {
  @media screen and (max-width:435px) {
    width: 100px;
  }
}

.copyright {
  text-align: center;
  cursor: pointer;
  font-family: 'Montserrat-Medium', sans-serif;


  @media screen and (max-width:435px) {
    margin: -20px 0 0;
  }
}

.inner-row {
  background-color: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  // height: 17vh;
  width: 100%;
  height: 140px;
  position: absolute;
  bottom: 0;
  margin: 0 auto !important;



  /* Mobile devices */
  @media only screen and (max-width: 767px) {
    height: 140px;
    position: absolute;
    bottom: 0;
    width: 100% !important;
  }

  /* Tablets */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    height: 140px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}

.iphone-inner-row {
  background-color: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  height: 200px;
  bottom: 0;
  position: fixed;
  margin: 0 !important;
  width: 100%;
}

.success-inner-row {
  background-color: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  // height: 17vh;
  width: 100%;
  height: 140px;
  bottom: 0 !important;
  margin: 0 !important;
  position: fixed;


  @media screen and (max-width:435px) {
    width: 100%;
    // margin-top: 30px;
    height: 90px;
  }
}


.term-text {
  font-size: 11px;
  color: #4a698b;
  padding-right: 3px;
  font-weight: 500;
}

.term-word {
  font-size: 11px;
  color: #1eaae6;
  font-weight: bold;
}


.iphone-term-text {
  font-size: 3vw;
  color: #4a698b;
  padding-right: 3px;
  font-weight: 500;
}

.iphone-term-word {
  font-size: 3vw;
  color: #1eaae6;
  font-weight: bold;
}

.logo-img {
  // width: 50px;
  // height: auto;
  border-radius: 100px;
  margin: 10px 0 17px 0px;
  cursor: pointer;
  // margin-bottom: 30px;
}


.pay-bill {
  background-image: url("./assets/home/pay-bg.png");
  background-size: cover;
  background-position: start;
  // overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: 100vh;

  @media screen and (max-width:435px) {
    .card-info {
      background-image: url("./assets/home/bill-card.svg");
      overflow: hidden !important;
      background-repeat: no-repeat;
      padding-left: 31px;
      padding-right: 55px;
    }

    .title-card {
      font-size: 17px;
      color: #7855d3;
      font-family: 'Montserrat-Bold', sans-serif;
      margin-left: 10%;
      font-weight: bold;
    }

    .title-desc {
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      color: #1a446d;
      font-family: 'Montserrat-Medium', sans-serif;
    }

    .title-optional {
      font-size: 10px;
      color: #1a446d;
      margin-top: -15px;
      font-family: 'Montserrat-Medium', sans-serif;
    }

    .ingredients {
      font-size: 10px;
      color: #1a446d;
      margin-top: -20px;
      text-align: left;
      font-family: 'Montserrat-Medium', sans-serif;
    }

    .quantity {
      color: #7855d3;
      font-size: 12px;
      font-weight: 600;
      font-family: 'Montserrat-SemiBold', sans-serif;
    }

    .food-names {
      font-size: 12px;
      color: #1a446d;
      font-weight: 500;
      // text-align: left;
      font-family: 'Montserrat-Medium', sans-serif;
    }

    .total {
      color: #1a446d;
      font-size: 18px;
      font-weight: 500;
      // text-align: left;
      font-family: 'Montserrat-Medium', sans-serif;

    }

    .share {
      color: #25436a;
      font-size: 15px;
      font-weight: 500;
      // text-align: left;
      font-family: 'Montserrat-Medium', sans-serif;
      margin-top: -10px;
      padding: 7px 80px 12px 80px;

      &:hover {
        color: #fff;
      }
    }

    .swipe {
      color: rgba(26, 68, 109, 0.5);
      font-size: 12px;
    }

    .question {
      color: #25436a;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Montserrat-SemiBold', sans-serif;
    }

    .card-other {
      background-color: transparent !important;
      border: 1px solid #1a446d;
      border-radius: 10px;
      width: 95%;
      height: 48px;
      padding-top: 10px;
      // padding-left: 22px;
      box-shadow: none;
    }

    .card-completed {
      background-color: transparent !important;
      border: 1px solid #1a446d;
      border-radius: 10px;
      width: 323px;
      height: 48px;
      padding-top: 10px;
      // padding-left: 22px;
      box-shadow: none;
      justify-content: center;
      align-self: center;

      .btn-modal {
        color: #1a446d;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Montserrat-Medium', sans-serif;
        padding: 0 0 0 27px;

      }

      &:hover {
        background-color: #1A446D !important;
        color: #fff !important;

        .btn-modal {
          color: #fff !important;
        }
      }
    }

    .card-other-active {
      background-color: rgba(120, 85, 211, 0.6) !important;
      border-radius: 10px;
      width: 280px;
      height: 48px;
      padding-top: 10px;
      // padding-left: 22px;
      box-shadow: none;
      color: white;

      .md-form {
        input {
          color: white;
        }
      }

      svg {
        path {
          stroke: white !important
        }

        circle {
          stroke: white !important
        }

        .filled-svg {
          fill: white !important
        }
      }

      .total {
        color: white !important;
      }
    }

    .pay-btn-card {
      background-color: white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 100%;
      // height: 16%;
      // height: 21vh;
      position: fixed;
      bottom: 0px;
      margin: 0 auto !important;
    }

    .iphone-pay-btn-card {
      height: 12vh;
      background-color: white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 100%;
      position: fixed;
      bottom: 0px;
      margin: 0 auto !important;
    }

    .bottom-total {
      color: #1a446d;
      font-size: 14px;
      margin-left: 0px;
      font-family: 'Montserrat-Medium', sans-serif;

    }

    .total-cost {
      color: #00beee;
      font-size: 22px;
      margin-left: -40px;
      font-family: 'Montserrat-Bold', sans-serif;
    }

    .back {
      position: absolute;
      left: 40px;
      top: 45px;
      margin-left: 20px;
    }

  }

}

.card-info {
  background-image: url("./assets/home/bill-card.svg");
  overflow: hidden !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.title-card {
  font-size: 20px;
  color: #7855d3;
  font-family: 'Montserrat-Bold', sans-serif;
  margin-left: 10%;
  font-weight: bold;
}

.arabic-title-card {
  font-size: 20px;
  color: #7855d3;
  font-family: 'Montserrat-Bold', sans-serif;
  margin-right: 10%;
}

.title-optional {
  font-size: 10px;
  color: #1a446d;
  // margin-top: -15px;
  font-family: 'Montserrat-Medium', sans-serif;

}

.ingredients {
  font-size: 10px;
  color: #1a446d;
  // margin-top: -20px;
  margin-left: -65px;
  font-family: 'Montserrat-Medium', sans-serif;

}

.quantity {
  color: #7855d3;
  font-size: 12px;
  margin-left: 0px;
  font-weight: 600;
  font-family: 'Montserrat-SemiBold', sans-serif;


}

.quantityDesktop {
  color: #7855d3;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  position: absolute;
  margin: 0px 260px 0px 0px
}

.food-names {
  font-size: 12px;
  color: #1a446d;
  font-weight: 500;
  font-family: 'Montserrat-Medium', sans-serif;

}

.total {
  color: #1a446d;
  font-size: 18px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-weight: 500;
}


.swipe {
  color: rgba(26, 68, 109, 0.5);
  font-size: 13px;
  font-weight: 500;
}

.question {
  color: #25436a;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Montserrat-SemiBold', sans-serif;

}

.mini-cards {
  background-color: transparent !important;
  border: 1px solid #1a446d;
  border-radius: 10px;
  width: 80px;
  height: 48px;
  padding-top: 10px;
  // padding-left: 22px;
  box-shadow: none;
  cursor: pointer;

}

.mini-cards-active {
  background-color: rgba(120, 85, 211, 0.6) !important;
  border-radius: 10px;
  width: 80px;
  height: 48px;
  padding-top: 10px;
  // padding-left: 22px;
  box-shadow: none;
  color: white;
  cursor: pointer;

  .md-form {
    input {
      color: white;
    }
  }

  svg {
    path {
      stroke: white !important
    }

    circle {
      stroke: white !important
    }

    .filled-svg {
      fill: white !important
    }
  }

  .total {
    color: white !important;
  }

}


.card-other {
  background-color: transparent !important;
  border: 1px solid #1a446d;
  border-radius: 10px;
  width: 95%;
  height: 48px;
  padding-top: 10px;
  // padding-left: 22px;
  box-shadow: none;
}

.card-completed {
  background-color: transparent !important;
  border: 1px solid #1a446d;
  border-radius: 10px;
  width: 323px;
  height: 48px;
  // padding-left: 22px;
  box-shadow: none;
  justify-content: center;
  align-self: center;
  align-items: center;

  .btn-modal {
    color: #1a446d;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Montserrat-Medium', sans-serif;
    padding: 0 0 0 27px;

  }

  &:hover {
    background-color: #1A446D !important;
    color: #fff !important;

    .btn-modal {
      color: #FFF !important;
    }
  }

}

.card-other-active {
  background-color: rgba(120, 85, 211, 0.6) !important;
  border-radius: 10px;
  width: 280px;
  height: 48px;
  padding-top: 10px;
  // padding-left: 22px;
  box-shadow: none;
  color: white;

  .md-form {
    input {
      color: white;
    }
  }

  svg {
    path {
      stroke: white !important
    }

    circle {
      stroke: white !important
    }

    .filled-svg {
      fill: white !important
    }
  }

  .total {
    color: white !important;
  }

}

.pay-btn-card {
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  // height: 16%;
  position: fixed;
  bottom: 0px;
  margin: 0 auto !important;
}

.iphone-pay-btn-card {
  height: 12vh;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  margin: 0 auto !important;
}

.bottom-total {
  color: #1a446d;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;

  // margin-left: 60%;
}

.total-cost {
  color: #00beee;
  font-size: 22px;
  font-family: 'Montserrat-Bold', sans-serif;
  margin-left: -40px;
}

.back {
  position: absolute;
  // left: 42%;
  top: 50px;
  margin-left: -90px;
  cursor: pointer;
}

.tips {
  font-size: 12px;
  color: #1a446d;
  text-align: left;

}

.modal-title {
  color: #1a446d;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Montserrat-Bold', sans-serif;
}

.modal-text {
  color: #1a446d;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Montserrat-Medium', sans-serif
}

.split-option {
  font-size: 15px;
  font-weight: 500;
  color: #25436a;
  font-family: 'Montserrat-Medium', sans-serif;
}

.try-again-btn {
  border-radius: 23px;
  background-color: #00beee !important;
  width: 80%;
  font-size: 15px;
  font-weight: 900;
  color: #fff !important;
  box-shadow: 0 0 20px 0 rgba(50, 157, 156, 0.21);
  font-family: 'Montserrat-SemiBold', sans-serif;
  text-transform: capitalize;

}

.back-to-bill-btn {
  border-radius: 23px;
  background-color: #1A446D !important;
  width: 325px;
  font-size: 15px;
  font-weight: 900;
  z-index: 22;
  text-transform: capitalize;
  font-family: 'Montserrat-SemiBold', sans-serif;

  &:hover {
    background-color: #fff !important;
    color: #1A446D !important;
    border: 1px solid #1A446D !important;
    z-index: 22;
  }

}

.discard {
  width: 94px;
  height: 94px;
  margin: 25px auto;
}

.discard-btn {
  width: 80%;
  height: 48px;
  object-fit: contain;
  border-radius: 24px;
  border: solid 1px #1fa9e5;
  background-color: #fff !important;
  color: #1fa9e5;

  &:hover {
    background-color: #fff !important;
    color: #1fa9e5;
  }

  &:active {
    background-color: #fff !important;
    color: #1fa9e5;
  }
}

.modal-dialog .modal-content {
  border-radius: 9px !important;
  padding: 10px;
}

.container-img {
  height: 102.8vh;
  width: 480px;

  // -webkit-backdrop-filter: blur(5px);
  // backdrop-filter: blur(5px);
  @media screen and (max-width:435px) {
    height: 96vh;
  }
}

.iphone-container-img {
  height: 96vh;
  max-width: 100% !important;
}

.totalBill {
  font-size: 16px;
  font-weight: bold;
  color: #1a446d;
  font-family: 'Montserrat-Bold', sans-serif;
}

.iphone-totalBill {
  font-weight: bold;
  color: #1a446d;
  margin: 11px 0px 11px 0px;
  font-size: 3vw;
  font-family: 'Montserrat-Bold', sans-serif;
}

.pay-now-btn {
  border-radius: 24px;
  width: 155px;
  height: 48px;
  object-fit: contain;
  background-image: linear-gradient(to right, #3d00da, #1fa9e5);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 900;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin: 19px 0px 11px 0px;
}

.pay-now-btn-split {
  border-radius: 24px;
  width: 155px;
  height: 48px;
  object-fit: contain;
  background-image: linear-gradient(to right, #3d00da, #1fa9e5);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 900;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.split-btn {
  width: 166px;
  height: 48px;
  object-fit: contain;
  border-radius: 24px;
  border: solid 1px #1fa9e5;
  background-color: #fff !important;
  color: #1fa9e5;
  margin: 19px 0px 11px 0px;
  text-transform: capitalize !important;
  font-size: 17px;
  font-weight: bold;
  font-family: 'Montserrat-SemiBold', sans-serif;

  &:hover {
    background-color: #fff !important;
    color: #1fa9e5;
  }

  &:active {
    background-color: #fff !important;
    color: #1fa9e5;
  }
}

.iphone-split-btn {
  width: 100%;
  height: 4vh;
  object-fit: contain;
  border-radius: 5vw;
  border: solid 1px #1fa9e5;
  background-color: #fff !important;
  color: #1fa9e5;
  margin: 19px 0px 11px 0px;
  text-transform: capitalize !important;
  font-size: 4vw;
  font-weight: bold;
  font-family: 'Montserrat-SemiBold', sans-serif;

}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show>.btn-default.dropdown-toggle {
  border: solid 1px #1fa9e5;
  background-color: #fff !important;
  color: #1fa9e5;
}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show>.btn-default.dropdown-toggle {
  box-shadow: none;
}

.input-card {
  margin-top: -40px !important;
  padding: 15px !important;
  color: #fff !important;
  z-index: 11111;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  opacity: 1 !important;
  border: none !important;
  border-color: none !important;
  font-family: 'Montserrat-Medium', sans-serif;
  border-bottom: none !important;
}

.percent {
  position: absolute;
}


.container {
  position: relative;
  display: inline-block;
}

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 400px;
}

.top-bar {
  background-image: url("./assets/home/top-bar.png");
  // overflow: hidden !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
}

.body-bar {
  background-image: url("./assets/home/body-bar.png");
  background-size: 100% auto;
  background-position: center;
  height: 20vh;
  overflow-y: auto;
}

.bottom-bar {
  background-image: url("./assets/home/btn-bar.png");
  overflow: hidden !important;
  background-repeat: no-repeat;
  background-size: cover;
  // padding: 0 0 20px;
}

.centered-div {
  width: 360px;
  margin: auto;
}

.iphone-centered-div {
  width: 100%;
  margin: auto;
}

.ingredientsDesktop {
  font-size: 8px;
  font-weight: 500;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #1a446d;
}


.lang-name {
  font-size: 15px;
  font-weight: 600;
  color: #9aa9bf;
}

.form-check-input[type="radio"]:checked+label:after {
  background-color: #1fa9e5 !important;
}

.form-check-input[type="radio"]:checked+label:after {
  border: 1px solid #1fa9e5 !important;
}

.form-check-input[type="radio"].with-gap:checked+label:before {
  border: 1px solid #a9a9a9 !important;
}

.form-check-input[type="radio"]:not(:checked)+label:before {
  border: 1px solid #a9a9a9 !important;
}

.split-options-card {
  width: 248px;
  height: 48px;
  border-radius: 10px;
  border: solid 1px #1a446d;
  box-shadow: none;
  cursor: pointer;
  color: #1a446d;
}

.dotted-line {
  width: 100%;
  height: 1px;
  margin: 0 0px 0px 12px;
  // background-color: #b7bfeb;
  border-top: 1px dotted;
  color: #b7bfeb;
}

.white-with-blue-border-btn {
  width: 100%;
  height: 45px;
  padding: 13px 34.5px 14px 34px;
  border-radius: 22.5px;
  // box-shadow: 0 0 20px 0 rgba(50, 157, 156, 0.21) !important;
  border: solid 1px #00beee !important;
  color: #00beee !important;
  background-color: #fff !important;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  font-family: 'Montserrat-Bold', sans-serif;

  &:hover {
    color: #fff !important;
    background-color: #1a446d !important;
  }
}

.blue-btn {
  width: 100%;
  height: 45px;
  padding: 13px 31.5px 14px 31px;
  border-radius: 22.5px;
  box-shadow: 0 0 20px 0 rgba(50, 157, 156, 0.21) !important;
  background-color: #00beee !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: 900;
  text-transform: capitalize;
}

.totalBill {
  font-weight: bold;
  color: #1a446d;
  margin: 11px 0px 11px 0px;
  font-size: 16px;
  font-family: 'Montserrat-Bold', sans-serif;
}

.iphone-totalBill {
  font-weight: bold;
  color: #1a446d;
  margin: 11px 0px 11px 0px;
  font-size: 3vw;
  font-family: 'Montserrat-Bold', sans-serif;
}


.right-to-left {
  direction: rtl;
  // text-align: right;

  .rotate {
    rotate: 180deg;
  }

  #split-bill-modal {
    .modal-content {
      height: 360px;
    }
  }
}

.left-to-right {
  direction: ltr;
  // text-align: left;
}


.qr-div {
  background-color: white;
  border-radius: 24px;
}

picture img {
  width: 31px;
  height: 31px;
  background-color: #fff;
  border-radius: 50px;
  margin-bottom: 10px;
}

.languageHeadder {
  display: inline;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 15.2px 10px 5px !important;
  color: #25436a;

  .modal-title {
    font-size: 19px;
    font-weight: bold;
    font-family: 'Montserrat-Bold', sans-serif;

  }
}

.back-arrow {
  z-index: 6;
  position: absolute;
  margin-top: 20px;
  cursor: pointer;
}

.iphone-back-arrow {
  z-index: 6;
  position: absolute;
  margin-top: 20px;
  cursor: pointer;
  width: 10vw;
}

.menu-frame {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0px;
  overflow: hidden;
  z-index: 5;
  display: block;
}

.money-paper {
  margin: 28.9px 75.8px 28.9px 76.9px;
  object-fit: contain;
}

.exceeded-amount {
  font-size: 17px;
  font-weight: 600;
  color: #1a446d;
}

.exceeded-value {
  font-size: 17px;
  font-weight: 600;
  color: #00beee;
}


@media (max-width: 1179px) {
  .modal-dialog-centered.modal-sm {
    top: -23%;
  }

}

.share-icon {
  float: right
}

.arabic-share-icon {
  float: left;
}

.payment-completed {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #25436a;
}

.success-desc {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #1a446d;
}

.success-btn-desc {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 15px;
  font-weight: 500;

}

.parentCell {
  width: 200px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

span.tooltip {
  width: 200px !important;
  word-break: break-word !important;
  z-index: 9999 !important;
}

.back-btn {
  @media only screen and (min-width: 1080px) and (max-width:1440px) {
    height: 3vh;
  }
}

.spliting {
  height: 80px;
  margin: 25px auto
}

.fs-11 {
  font-size: 11px;
}

.fs-13 {
  font-size: 13px;
}

.margin {
  margin-top: 5rem !important;
}