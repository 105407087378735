.active-splitOption {
    width: 100px;
    height: 50px;
    margin: 0 4px 0 0;
    padding: 5px 5px 3px 7px;
    background-color: #1a446d;
    border-radius: 10px;
    text-align: center;
}

.inactive-splitOption {
    width: 100px;
    height: 50px;
    /* margin: 0 4px 0 0; */
    /* padding: 5px 5px 3px 7px; */
    background-color: #fff;
    color: rgba(37, 67, 106, 0.75) !important;
    border-radius: 10px;
    text-align: center;
}

.minus-btn {
    width: 30px !important;
    height: 30px !important;
    // margin: 0 9px 8px 0;
    padding: 3.5px 10.3px 3.5px;
    // opacity: 0.35 !important;
    border-radius: 20px;
    background-color: rgba(120, 85, 211, 0.13) !important;
}

.plus-btn {
    width: 30px !important;
    height: 30px !important;
    // margin: 0 0 8px 9px;
    padding: 3.5px 10.3px 3.5px;
    border-radius: 20px;
    background-color: rgba(120, 85, 211, 0.13) !important;
}


.check-btn {
    width: 30px !important;
    height: 30px !important;
    // margin: 8px 0 8px 9px;
    padding: 8.4px 5.6px 8.4px 6.8px;
    border-radius: 20px;
    background-color: rgba(120, 85, 211, 0.13) !important;
}

.fa-plus {
    background-color: transparent !important;
    padding: 0 !important;
    color: rgba(120, 85, 211, 0.6) !important;
}

.fa-minus {
    background-color: transparent !important;
    padding: 0 !important;
    color: #b7b7b7 !important;
}

.fa-check {
    background-color: transparent !important;
    padding: 0 !important;
    color: rgba(120, 85, 211, 0.6) !important;
}


.md-form input:not([type]),
.md-form input[type="text"]:not(.browser-default),
.md-form input[type="password"]:not(.browser-default),
.md-form input[type="email"]:not(.browser-default),
.md-form input[type="url"]:not(.browser-default),
.md-form input[type="time"]:not(.browser-default),
.md-form input[type="date"]:not(.browser-default),
.md-form input[type="datetime"]:not(.browser-default),
.md-form input[type="datetime-local"]:not(.browser-default),
.md-form input[type="tel"]:not(.browser-default),
.md-form input[type="number"]:not(.browser-default),
.md-form input[type="search"]:not(.browser-default),
.md-form input[type="phone"]:not(.browser-default),
.md-form input[type="search-md"],
.md-form textarea.md-textarea {
    width: 87% !important;
    margin: 10px;
    opacity: 0.5;
    border-radius: 10px;
    border: solid 1px #96aac1;
    // color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    border-color: #96aac1 !important;
    // padding-left: 15px;
}

.bottom-amount {
    font-size: 13px;
    font-weight: 500;
    color: #96aac1;
    margin-top: 11px;
    margin-bottom: 11px;
}

.line {
    width: 100%;
    height: 1px;
    margin: 0 0px 0px 12px;
    color: #96aac1;
}

.tipAmount {
    font-size: 14px;
    font-weight: 500;
    color: #1a446d;
    margin: 7px 0px 0px 0px;
    font-family: 'Montserrat-Medium', sans-serif;
}

.iphone-tipAmount {
    font-size: 3vw;
    font-weight: 500;
    color: #1a446d;
    margin: 7px 0px 0px 0px;
    font-family: 'Montserrat-Medium', sans-serif;
}

.tipWithVATValue {
    font-size: 15px;
    font-weight: 500;
    color: #1a446d;
    font-family: 'Montserrat-Medium', sans-serif;

}

.your-share {
    font-size: 11px;
    font-weight: 500;
    color: #1a446d;
    font-family: 'Montserrat-Medium', sans-serif;


}

.blue-desc {
    font-size: 32px;
    font-weight: bold;
    color: #00beee;
}

.paying {
    font-size: 14px;
    font-weight: 600;
    color: #00beee;
    font-family: 'Montserrat-SemiBold', sans-serif;
}

.amountValue {
    font-size: 17px;
    font-weight: 500;
    color: #96aac1;
}

.tipValue {
    font-size: 15px;
    font-weight: 500;
    color: #1a446d;
}


.split-the-bill {
    overflow-x: hidden !important;
    height: 85vh;

    .split-the-bill-pay-btn-card {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        // height: 16%;
        position: fixed;
        bottom: 0px;
        margin: 0 !important;
    }

    .bottom-bar {
        overflow: hidden !important;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0 0 8px !important;
    }

    .fa-check-circle {
        position: absolute;
        right: 5px;
        top: 3px;
        color: white;
        width: 11px;
        height: 11px;
        font-size: 10px;
    }

    .fa-circle {
        position: absolute;
        right: 5px;
        top: 3px;
        color: #1a446d;
        width: 11px;
        height: 11px;
        font-size: 10px;
    }

    .amount-input {
        color: gray !important;
    }

    .md-form .input-card {
        margin-top: -40px !important;
        padding: 15px !important;
        color: #fff !important;
        z-index: 11111;
        text-align: right;
        font-size: 18px;
        font-weight: 500;
        opacity: 1 !important;
        border: none !important;
        border-color: none !important;
        font-family: 'Montserrat-Medium', sans-serif;
        border-bottom: none !important;
    }

    .back-to-top {
        position: fixed;
        width: 360px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        bottom: 20px;
        right: 20px;
        // background-color: #007bff; /* Background color */
        color: #fff;
        /* Text color */
        // padding: 10px 20px;
        border: none;
        // border-radius: 5px;
        text-decoration: none;
        // font-weight: bold;
        // font-size: 16px;
        margin: 0 auto;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    }

    .card-other {
        background-color: transparent !important;
        border: 1px solid #1a446d;
        border-radius: 10px;
        width: 327px;
        height: 48px;
        padding-top: 10px;
        // padding-left: 22px;
        box-shadow: none;
    }

    .card-other-active {
        background-color: rgba(120, 85, 211, 0.6) !important;
        border-radius: 10px;
        width: 323px;
        height: 48px;
        padding-top: 10px;
        // padding-left: 22px;
        box-shadow: none;
        color: white;

        .md-form {
            input {
                color: white;
            }
        }

        svg {
            path {
                stroke: white !important
            }

            circle {
                stroke: white !important
            }

            .filled-svg {
                fill: white !important
            }
        }

        .total {
            color: white !important;
        }

    }

    .mini-cards {
        background-color: transparent !important;
        border: 1px solid #1a446d;
        border-radius: 10px;
        width: 102px;
        height: 48px;
        padding-top: 10px;
        // padding-left: 22px;
        box-shadow: none;
        cursor: pointer;
    }

    .mini-cards-active {
        background-color: rgba(120, 85, 211, 0.6) !important;
        border-radius: 10px;
        width: 102px;
        height: 48px;
        padding-top: 10px;
        // padding-left: 22px;
        box-shadow: none;
        color: white;
        cursor: pointer;

        .md-form {
            input {
                color: white;
            }
        }

        svg {
            path {
                stroke: white !important
            }

            circle {
                stroke: white !important
            }

            .filled-svg {
                fill: white !important
            }
        }

        .total {
            color: white !important;
        }

    }


    .card-other {
        background-color: transparent !important;
        border: 1px solid #1a446d;
        border-radius: 10px;
        width: 323px;
        height: 48px;
        padding-top: 10px;
        // padding-left: 22px;
        box-shadow: none;
    }
}

.money-paper {
    margin: 28.9px 75.8px 28.9px 76.9px;
    object-fit: contain;
}

.exceeded-amount {
    font-size: 17px;
    font-weight: 600;
    color: #1a446d;
    font-family: 'Montserrat-SemiBold', sans-serif;
}

.exceeded-value {
    font-size: 17px;
    font-weight: 600;
    color: #00beee;
}

.split-bill {
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 22px;
    font-weight: bold;
    color: #1a446d;
}

.splitOptions {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Montserrat-Medium', sans-serif;
}

.option-desc {
    font-size: 18px;
    font-weight: 600;
    color: #1a446d;
    font-family: 'Montserrat-SemiBold', sans-serif;
}

.quantity-add {
    font-size: 12px;
    font-weight: 600;
    color: #7855d3;
    font-family: 'Montserrat-SemiBold', sans-serif;

}

.amountLeftToPay {
    font-family: 'Montserrat-Medium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #1a446d;
}

.total {
    font-size: 18px;
    font-weight: 600;
    color: #1a446d;
    font-family: 'Montserrat-SemiBold', sans-serif;

}

.blue-desc {
    font-size: 30px;
    font-weight: bold;
    color: #00beee;
    font-family: 'Montserrat-Bold', sans-serif;

}

.nbrOfPeople {
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #7855d3;
    padding-left: 6px;
    font-family: 'Montserrat-SemiBold', sans-serif;

}

.arabiNnbrOfPeople {
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #7855d3;
    padding-right: 6px;
    font-family: 'Montserrat-SemiBold', sans-serif;

}

.split-modal-text {
    font-size: 17px;
    font-weight: 500;
    color: #1a446d;
    font-family: 'Montserrat-Medium', sans-serif;
}

.gridDiv {
    display: grid;
    margin: -4px 0px 0px 0px;
}

.cursor-pointer {
    cursor: pointer;

}